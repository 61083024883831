import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { TextInput } from '../Form/Form';



function ContactForm({ setOpenModel }) {
    const [showGreeting, setSetshowGreeting] = useState(false)


    // method-2 
    // const form = useRef();

    // const sendEmail = (e) => {
    //     e.preventDefault();

    //     emailjs.sendForm('service_cxq8nsd', 'template_1xhgm8h', form.current, 'yD8-MeBkzC93MUxww')
    //         .then((result) => {
    //             console.log(result.text);
    //         }, (error) => {
    //             console.log(error.text);
    //         })
    //     e.target.reset()
    // };

    // method-1 - Not Working
    // const addContact = ({ values }) => {
    //     if (mail('ajitawadhiya@gmail.com', "this is test email!!", "test email")) {
    //         window.location.href = `/thankyou`;

    //     } else {
    //         console.log('Something Went Wrong');
    //         window.location.href = `/thankyou`;

    //     }
    // }

    const addContact = ({ values }) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://luhaifdigitech.com/mailer/mail.php?name=${values.name}&phone=${values.phone}&email=${values.email}&message=${values.message}`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    return (
        <div>
            <div className="contact-form">
                {showGreeting ? (
                    <div className="mt-5">
                        <div className="modal-close-icon thankyou-modal-close">
                            <i onClick={() => setOpenModel(false)} class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </div>
                        <div className="success-message-box">
                            <div className="success-message">
                                <h4>Thankyou!</h4>
                                <p>Thankyou for Successfully filling the form. Our Team will contact you regarding the same</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            phone: '',
                            message: '',
                        }}
                        validationSchema={yup.object({
                            name: yup.string().required('Required'),
                            email: yup.string().required('Required'),
                            phone: yup.string().required('Required'),
                        })}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            addContact({ values });
                            setSetshowGreeting(!showGreeting)
                            resetForm();
                            setSubmitting(false);
                        }}
                    >
                        {(formik) => {
                            console.log(formik);
                            return (
                                <Form className='request_form '>
                                    <h5 className='text-center'>Book an Appointment</h5>
                                    <div className='row'>
                                        <div className="col-md-12 mt-4">
                                            <TextInput
                                                label="Name"
                                                name="name"
                                                type="text"
                                                placeholder="Enter name"
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <TextInput
                                                label="Email"
                                                name="email"
                                                type="text"
                                                placeholder="Enter email"
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <TextInput
                                                label="Phone"
                                                name="phone"
                                                type="text"
                                                placeholder="Enter phone"
                                            />
                                        </div>
                                        <textarea
                                            name="message"
                                            id="message"
                                            cols="30"
                                            rows="10"
                                            type="text"
                                            placeholder="Enter message" />
                                    </div>
                                    <div className="contact-form-btn">
                                        <div className="form-submit-btn">
                                            <button
                                                type='submit'>Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                )}

            </div>
        </div>
    )
}

export default ContactForm