import React from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Header from '../../components/common/Header'
import Footer from '../../components/common/Footer'
import ServiceCard from './ServiceCard'
import { ServiceData } from '../../models/service'
import { MENU_TABS } from '../../domain/constant'



function Services() {

    return (
        <div>
            <Header activeTab={MENU_TABS.SERVICE} />
            <Breadcrumb path={`Our Services`} />
            <section className="service-section p_relative pt_120 pb_120">
                <div className="auto-container">
                    <div className='row clearfix'>
                        {
                            ServiceData && ServiceData.map((item) => {
                                return (
                                    <div className="col-md-4 col-sm-6">
                                        <ServiceCard
                                            img={item.img}
                                            title={item.title}
                                            short_desc={item.short_desc}
                                            link={item.slug}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Services