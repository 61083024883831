import React, { useState } from 'react'
// import DetailPageBookAppointment from '../../containers/collections/DetailPageBookAppointment'
import { Form, Formik } from 'formik'
import { SelectBox, TextArea, TextInput } from '../Form/Form'
// import { useCreateAppointment } from "../../shared/hooks/UseAppointment";
// import { useSelectAllService } from "../../shared/hooks/UseService"
import * as yup from "yup"

function BookAppointmentButton({ openModel, setOpenModel }) {
    // const [openModel, setOpenModel] = useState(false)

    // const [data, addData] = useCreateAppointment();

    // const [service_data] = useSelectAllService();
    // const { all_services, all_services_loading } = service_data;

    const [showGreeting, setSetshowGreeting] = useState(false)

    const addContact = ({ values }) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://luhaifdigitech.com/mailer/mail.php?name=${values.name}&phone=${values.phone}&email=${values.email}&message=${values.message}`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }
    console.log("Check if open", openModel);
    const handleModalClose = () => {

        setOpenModel(false);
        console.log("Closing Modal", openModel);
    }
    return (
        <div>
            {
                // <div className>
                //     <a onClick={() => setOpenModel(true)} className="btn btn-book">Book Appointment</a>
                // </div>
            }

            <div className='appointment-modal-wrapper' style={{ display: openModel ? "block" : "none" }}>
                <div className='appointment-modal'>
                    {showGreeting ? (
                        <>
                            <div className="modal-close-icon thankyou-modal-close">
                                <i onClick={() => setOpenModel(false)} className="fa fa-times-circ" aria-hidden="true"></i>
                            </div>
                            <div className="success-message-box">
                                <div className="success-message">
                                    <h4>Thankyou!</h4>
                                    <p>Thankyou for Successfully filling the form. Our Team will contact you regarding the same</p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="formik-form">
                            <Formik
                                initialValues={{
                                    name: '',
                                    email: '',
                                    phone: '',
                                    message: '',
                                }}
                                validationSchema={yup.object({
                                    name: yup.string().required('Required'),
                                    email: yup.string().required('Required'),
                                    phone: yup.string().required('Required'),
                                })}
                                onSubmit={async (values, { setSubmitting, resetForm }) => {
                                    setSubmitting(true);
                                    addContact({ values });
                                    setSetshowGreeting(!showGreeting)
                                    resetForm();
                                    setSubmitting(false);
                                }}
                            >
                                {(formik) => {
                                    console.log(formik);
                                    return (
                                        <Form className='request_form '>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="d-flex-justify-space-btn">

                                                        <div class="modal-appointment-form-heading">
                                                            <h5 className='text-center'>Book an Appointment</h5>
                                                            <div className="modal-close-icon thankyou-modal-close" onClick={() => handleModalClose()}>
                                                                <i class="fa fa-times" aria-hidden="true"></i>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-12 mt-4">
                                                    <TextInput
                                                        label="Name"
                                                        name="name"
                                                        type="text"
                                                        placeholder="Enter name"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <TextInput
                                                        label="Email"
                                                        name="email"
                                                        type="text"
                                                        placeholder="Enter email"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <TextInput
                                                        label="Phone"
                                                        name="phone"
                                                        type="text"
                                                        placeholder="Enter phone"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <textarea
                                                        name="message"
                                                        id="message"
                                                        cols="42"
                                                        rows="10"
                                                        type="text"
                                                        placeholder="Enter message" />
                                                </div>
                                            </div>
                                            <div className="contact-form-btn">
                                                <div className="form-submit-btn">
                                                    <button
                                                        type='submit'>Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    )}


                </div>
            </div>
        </div>
    )
}

export default BookAppointmentButton