import React from 'react';

const MobileHeader = () => {
  return (
    <div>
      <div className='mobile-menu'>
        <div className='menu-backdrop' />
        <div className='close-btn'>
          <i className='fas fa-times' />
        </div>
        <nav className='menu-box'>
          <div className='nav-logo'>
            <a href='/'>
              <img src="assets/images/logo-nzec.png" alt='logo' />
            </a>
          </div>
          <div className='menu-outer'>
            {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
          </div>
          <div className='contact-info'>
            <h4>Contact Info</h4>
            <ul>
              <li>SCO 81, 1st Floor Mahila Ashram, behind Bus Stand, Karnal, Haryana 132001</li>
              <li>
                <a href='tel:+8801682648101'>+64-999999999999</a>
              </li>
              <li>
                <a href='mailto:info@example.com'>support@nzec.com</a>
              </li>
            </ul>
          </div>
          <div className='social-links'>
            <ul className='clearfix'>
              <li>
                <a href='/'>
                  <span className='fab fa-twitter' />
                </a>
              </li>
              <li>
                <a href='/'>
                  <span className='fab fa-facebook-square' />
                </a>
              </li>
              <li>
                <a href='/'>
                  <span className='fab fa-pinterest-p' />
                </a>
              </li>
              <li>
                <a href='/'>
                  <span className='fab fa-instagram' />
                </a>
              </li>
              <li>
                <a href='/'>
                  <span className='fab fa-youtube' />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MobileHeader;
