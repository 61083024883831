import React, { useState } from 'react';
import { ServiceData } from '../../models/service';
import { CountryData } from '../../models/country';
import * as qs from "qs";
import { Link, useNavigate } from 'react-router-dom'
import BookAppointmentButton from './BookAppointmentButton';
import { MENU_TABS } from '../../domain/constant';


function Header({ activeTab = MENU_TABS.HOME }) {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate()
  const [openModel, setOpenModel] = useState(false)

  // const doSearch = () => {
  //   const queryParams = qs.parse(window.location.search.replace("?", ""));
  //   queryParams["search"] = { name: searchTerm };
  //   const query = qs.stringify(queryParams, {
  //     encodeValuesOnly: true, // prettify url
  //   });
  //   navigate(`/hospitals?${query}`);
  // };
  return (
    <header className='main-header'>
      <div className='auto-container'>
        <div className='header-top'>
          <div className='top-inner clearfix  '>
            <div className='left-column pull-left'>
              <ul className='info-list clearfix'>
                <li>
                  <i className='fas fa-phone-square' />
                  <a href="tel:+91 184 400 1188">+91 184 400 1188</a>
                </li>
                <li>
                  <i className='fas fa-phone-square' />
                  <a href="tel:+91 989 609 2188">+91 989 609 2188</a>
                </li>
                <li>
                  <i className='fas fa-envelope' />
                  <a href='mailto:needhelp@company.com'>info@nzecgroup.co.in</a>
                </li>
              </ul>
            </div>
            <div className='right-column pull-right'>
              <ul className='social-links clearfix'>
                <li>
                  <a href='/' target="_blank">
                    <i className='fab fab fa-twitter' />
                  </a>
                </li>
                <li>
                  <a href='https://www.facebook.com/NZECGROUP/' target="_blank">
                    <i className='fab fab fa-facebook-f' />
                  </a>
                </li>
                <li>
                  <a href='https://www.linkedin.com/company/nzec-group-study-abroad/about/' target="_blank">
                    <i className='fab fab fa-linkedin' />
                  </a>
                </li>
                <li>
                  <a href='https://www.instagram.com/nzecgroup/?hl=en' target="_blank">
                    <i className='fab fab fa-instagram' />
                  </a>
                </li>
              </ul>
              <div className='btn-box' onClick={() => setOpenModel(true)}>
                <p>Book Appointment</p>
              </div>
              <BookAppointmentButton openModel={openModel} setOpenModel={setOpenModel} />
            </div>
          </div>
        </div>
        {/* header-lower */}
        <div className='header-lower'>
          <div className='outer-box clearfix'>
            <div className='logo-box'>
              <figure className='logo'>
                <a href='/'>
                  <img src='/assets/images/logo-nzec.png' />
                </a>
              </figure>
            </div>
            <div className='menu-area clearfix'>
              {/*Mobile Navigation Toggler*/}
              <div className='mobile-nav-toggler'>
                <i className='icon-bar' />
                <i className='icon-bar' />
                <i className='icon-bar' />
              </div>
              <nav className='main-menu navbar-expand-md navbar-light'>
                <div
                  className='collapse navbar-collapse show clearfix'
                  id='navbarSupportedContent'
                >
                  <ul className='navigation clearfix'>
                    <li className={activeTab === MENU_TABS.HOME && 'current'}>
                      <a href='/'>Home </a>

                    </li>
                    <li className={activeTab === MENU_TABS.ABOUT ? 'dropdown current' : 'dropdown'}>
                      <a href='/about-us'>About Us</a>
                      <ul>
                        <li>
                          <Link to={`/about-us`}>About Us</Link>
                        </li>
                        <li>
                          <Link to={`/our-agents`}>Our Team</Link>
                        </li>
                      </ul>
                    </li>
                    <li className={activeTab === MENU_TABS.SERVICE ? 'dropdown current' : 'dropdown'}>
                      <a href='/services'>Services</a>
                      <ul>
                        {ServiceData && ServiceData.map((item) => {
                          return (
                            <li>
                              <Link to={`/service/${item.slug}`}>{item.title}</Link>
                            </li>
                          )
                        })}
                      </ul>
                    </li>
                    <li className={activeTab === MENU_TABS.COUNTRIES ? 'dropdown current' : 'dropdown'}>
                      <a href='/countries'>New Zealand</a>

                    </li>
                    {/* <li className={activeTab === MENU_TABS.COUNTRIES ? 'dropdown current' : 'dropdown'}>
                      <a href='/countries'>Countries</a>
                      <ul>
                        {CountryData && CountryData.map((item) => {
                          return (
                            <li>
                              <Link to={`/country/${item.slug}`}>{item.title}</Link>
                            </li>
                          )
                        })}
                      </ul>
                    </li> */}
                    <li className={activeTab === MENU_TABS.CAREER && 'current'}>
                      <Link to={`/Career`}>Careers</Link>
                    </li>
                    <li className={activeTab === MENU_TABS.PARTNER_WITH_US && 'current'}>
                      <Link to={`/partner-with-us`}>Partner with us</Link>
                    </li>
                    <li className={activeTab === MENU_TABS.CONTACT_US && 'current'}>
                      <Link to={`/contact-us`}>Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </nav>
              {
                //   <div className='nav-right clearfix'>
                //   <div className='search-box-outer'>
                //     <div className='dropdown'>
                //       <button
                //         className='search-box-btn'
                //         type='button'
                //         id='dropdownMenu3'
                //         data-toggle='dropdown'
                //         aria-haspopup='true'
                //         aria-expanded='false'
                //       >
                //         <i className='flaticon-magnifying-glass' />
                //       </button>
                //       <div
                //         className='dropdown-menu search-panel'
                //         aria-labelledby='dropdownMenu3'
                //       >
                //         <div className='form-container'>
                //           <form method='post' action='blog.html'>
                //             <div className='form-group'>
                //               <input
                //                 type='search'
                //                 name='search-field'
                //                 defaultValue
                //                 placeholder='Search....'
                //                 required
                //               />
                //               <button type='submit' className='search-btn'>
                //                 <span className='fas fa-search' />
                //               </button>
                //             </div>
                //           </form>
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // </div>
              }
            </div>
          </div>
        </div>
      </div>
      {/*sticky Header*/}
      <div className='sticky-header'>
        <div className='auto-container'>
          <div className='outer-box clearfix'>
            <div className='logo-box pull-left'>
              <figure className='logo'>
                <a href='/'>
                  <img src='/assets/images/logo-nzec.png' alt='logo' />
                </a>
              </figure>
            </div>
            <div className='menu-area clearfix pull-right'>
              <nav className='main-menu clearfix'>
                {/*Keep This Empty / Menu will come through Javascript*/}
              </nav>
              {
                //    <div className='nav-right clearfix'>
                //    <div className='search-box-outer'>
                //      <div className='dropdown'>
                //        <button
                //          className='search-box-btn'
                //          type='button'
                //          id='dropdownMenu4'
                //          data-toggle='dropdown'
                //          aria-haspopup='true'
                //          aria-expanded='false'
                //        >
                //          <i className='flaticon-magnifying-glass' />
                //        </button>
                //        <div
                //          className='dropdown-menu search-panel'
                //          aria-labelledby='dropdownMenu4'
                //        >
                //          <div className='form-container'>
                //            <form method='post' action='blog.html'>
                //              <div className='form-group'>
                //                <input
                //                  type='search'
                //                  name='search-field'
                //                  defaultValue
                //                  placeholder='Search....'
                //                  required
                //                />
                //                <button type='submit' className='search-btn'>
                //                  <span className='fas fa-search' />
                //                </button>
                //              </div>
                //            </form>
                //          </div>
                //        </div>
                //      </div>
                //    </div>
                //  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
