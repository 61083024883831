import React from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import ContactForm from '../../components/contact/ContactForm'
import Header from '../../components/common/Header'
import Footer from '../../components/common/Footer'
import { MENU_TABS } from '../../domain/constant'



function Career() {
    return (
        <div>
            <Header activeTab={MENU_TABS.CAREER} />
            <Breadcrumb path={`Career`} />
            <section className="career-section ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact-form">
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div>
                                <h3><strong>Join Our Team</strong></h3>
                                <p>We are a firm having 12+ years of experience in study abroad and student visas. Our team is equipped with experts of over 20 years from the same industry. We at NZEC Global, provide various services to make the students’ study abroad journey smooth in different countries. Now we are in search of skilled professionals to start working with our team for the goodwill of those who are seeking higher education abroad.</p>

                                <p>We never say no to individuals seeking growth opportunities, ensuring to let them choose their career path and mold their skills.  We aim to provide an inclusive, welcoming, and diverse work environment, resulting professional and personal growth of our employees. The remuneration provided by us is best as per the industry standard. </p>

                                <p>If you are looking for an opportunity like this and feel like you will be the best fit for this role then without giving a second thought send us your resume and grab the opportunity to work with one of the best Study Abroad Consultants. </p>
                                <hr />


                                <h3><strong>Why Join NZEC?</strong></h3>
                                <p><strong>1. Passion for Global Mobility: </strong>In our office, we emphasize the magic of travel and its power to expand perspective. As a team member, you will have the chance to nurture mobility and enhance relationships. </p>
                                <p><strong>2. Professional Growth: </strong>By joining our team, you’ll be having opportunities for advancement and learning. We are committed to the fact- skilled employees bring success to the company which is why we give our employees the room to grow. </p>
                                <p><strong>3. Innovative Work Environment: </strong>Innovation is our middle name if you join our team, you get a chance to work with top-notch tools and be part of a creative team. </p>
                                <p><strong>4. Diverse and Inclusive Environment: </strong>Our team values diversity and inclusivity and tries to create an inclusive environment in the office to make everyone feel welcome. </p>
                                <p><strong>5. Customer-centric Approach: </strong>Our goal is to give a good experience to our customers by providing top-tier solutions. </p>
                                <hr />
                                <h3><strong>Current Job Openings</strong></h3>
                                <p>Following are the job openings at our agency, explore and find out which fits you well: </p>
                                <p>1.	Customer Support Specialist: By joining this department you’ll be able to help our customers by navigating the visa application process. </p>
                                <p>2.	Visa Processing Agent: Be the one helping customers with visa applications along with our experts’ guidance. </p>
                                <p>3.	Sales and Development: Connect with new clients by joining our sales team. </p>
                                <p>4.	Marketing and Communication: Through content creation and marketing campaigns, you can make our online presence.</p>
                                <p>5.	Technology and Development: We have innovative solutions for all sorts of problems, join our team and get the chance to work on innovative solutions. </p>
                                <hr />

                                <h3><strong>How to Apply</strong></h3>
                                <p>Mail us your updated CV and cover letter to <strong>career@nzec.com</strong> and kindly mention why you want to join our team and how you can contribute to the growth and development of our organization. </p>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Career