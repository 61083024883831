import React from 'react'
import FeaturedCollegeCard from './FeaturedCollegeCard'
import Slider from "react-slick";
import { Link } from 'react-router-dom';


function FeaturedCollege() {
    var settings = {
        infinite: true,
        slidesToShow: 5,
        autoplay: true,
        arrow: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    return (
        <div>
            <section className="clients-section p_relative ptb-90 mt-3">
                <div className="auto-container ">
                    <Slider {...settings}>
                        <div>
                            <FeaturedCollegeCard link={`/contact-us`} featured_img={`/assets/images/colleges/ucol.jpeg`} />
                        </div>
                        <div>
                            <FeaturedCollegeCard link={`/contact-us`} featured_img={`/assets/images/colleges/witt.jpeg`} />
                        </div>
                        <div>
                            <FeaturedCollegeCard link={`/contact-us`} featured_img={`/assets/images/colleges/eit-header-tepukenga.svg`} />
                        </div>
                        <div>
                            <FeaturedCollegeCard link={`/contact-us`} featured_img={`/assets/images/colleges/unitec_te_pukenga.svg`} />
                        </div>
                        <div>
                            <FeaturedCollegeCard link={`/contact-us`} featured_img={`/assets/images/colleges/logo-stacked.svg`} />
                        </div>
                        <div>
                            <FeaturedCollegeCard link={`/contact-us`} featured_img={`/assets/images/colleges/west.jpeg`} />
                        </div>
                        <div>
                            <FeaturedCollegeCard link={`/contact-us`} featured_img={`/assets/images/colleges/sit.jpeg`} />
                        </div>
                        <div>
                            <FeaturedCollegeCard link={`/contact-us`} featured_img={`/assets/images/colleges/ara.jpeg`} />
                        </div>


                    </Slider>
                </div>
            </section>

        </div>
    )
}

export default FeaturedCollege