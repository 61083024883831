import React, { useState } from 'react';
import { Field, useField } from 'formik';
import EditorWYSIWYG from './EditorWYSIWYG';
import { useEffect } from 'react';
import { api } from '../../domain/api';
import { URI } from '../../domain/constant';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import axios from 'axios';
export const SelectBox = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className='form-floating mb-3'>
        <select className='form-control' {...field} {...props} />
        <label htmlFor={props.name}>{label}</label>

        {meta.error ? (
          <div className='text-danger err'>{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

export const MultiSelect = ({
  field,
  form,
  options,
  isMulti = false,
  placeholder = 'Select',
}) => {
  function onChange(option) {
    form.setFieldValue(
      field.name,
      option ? option.map((item) => item.value) : []
    );
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
          (option) => field.value && field.value.indexOf(option.value) >= 0
        )
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : '';
    }
  };

  if (!isMulti) {
    return (
      <Select
        options={options}
        name={field.name}
        value={
          options ? options.find((option) => option.value === field.value) : ''
        }
        onChange={(option) => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
        placeholder={placeholder}
      />
    );
  } else {
    return (
      <Select
        className='react-select-container'
        classNamePrefix='react-select'
        name={field.name}
        value={getValue()}
        onChange={onChange}
        options={options}
        isMulti={true}
        placeholder={placeholder}
      />
    );
  }
};
export const ReactSelect = ({
  label,
  name,
  options,
  isMulti,
  id,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className='form-floating mb-3'>
        <Field
          name={name}
          className='form-control'
          isMulti={true}
          component={MultiSelect}
          options={options}
        />
        <label htmlFor={name}>{label}</label>
        {meta.touched && meta.error ? (
          <div className='text-danger err'>{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

export const FileUpload2 = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className=' mb-3'>
        <select className='form-control' {...field} {...props} />
        <label htmlFor={props.name}>{label}</label>

        {meta.touched && meta.error ? (
          <div className='text-danger err'>{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};
export const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className='form-floating  '>
      <input
        id={props.name}
        placeholder={label}
        className='form-control '
        {...field}
        {...props}
      />
      <label htmlFor={props.name}>{label}</label>

      {meta.error ? <div className='text-danger err'>{meta.error}</div> : null}
    </div>
  );
};
export const PasswordInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  const [showPassword, setshowPassword] = useState(false);
  return (
    <>
      <div id='show_pas'>
        <div className='form-floating  '>
          <input
            id={props.name}
            placeholder={label}
            className='form-control '
            type={showPassword ? 'text' : 'password'}
            {...field}
            {...props}
          />
          <label htmlFor={props.name}>{label}</label>
        </div>
        <div className='' onClick={() => setshowPassword(!showPassword)}>
          <span
            className={
              showPassword
                ? 'fa fa-fw fa-eye field-icon '
                : 'fa fa-fw fa-eye-slash field-icon '
            }
          />
        </div>
      </div>
      {meta.error ? <div className='text-danger err'>{meta.error}</div> : null}
    </>
  );
};

export const TextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className='form-floating text mb-3'>
      <textarea
        id={props.name}
        placeholder={label}
        className='form-control'
        {...field}
        {...props}
      />
      <label htmlFor={props.name}>{label}</label>
      {meta.touched && meta.error ? (
        <div className='text-danger'>{meta.error}</div>
      ) : null}
    </div>
  );
};

export const RadioButton = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <input type='radio' className='form-check-input' {...field} {...props} />
      <label className='form-check-label' htmlFor={props.name}>
        {label}
      </label>
      {meta.touched && meta.error ? (
        <div className='text-danger'>{meta.error}</div>
      ) : null}
    </>
  );
};

export const CheckBox = ({ children, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <div className='form-group'>
      <div className='pt-3'>
        <label className='checkbox'>
          <input id={props.name} type='checkbox' {...field} {...props} />
          {'  '} {children}
        </label>
        {meta.touched && meta.error ? (
          <div className='error'>{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};
export const HTMLEditor = ({ title, item, formik, col, value }) => {
  const [showCode, setShowCode] = useState(false);
  return (
    <div className='form-floating text mb-3'>
      <div className='editor-flex'>
        <label> {title} </label>

        <div style={{ padding: '0px 10px' }}>
          <a
            onClick={() => setShowCode(!showCode)}
            className=' btn-view text-black mb-2'
          >
            {' '}
            View {showCode ? 'Editor' : 'Code'}
          </a>
        </div>
      </div>
      {showCode ? (
        <textarea
          id={item}
          className='form-control'
          value={value ? value : ''}
          name={item}
          onChange={formik.handleChange}
          rows={9}
        />
      ) : (
        <EditorWYSIWYG
          value={value ? value : ''}
          name={item}
          changeValue={(value) => {
            formik.setFieldValue(item, value);
          }}
        />
      )}

      {formik.errors && formik.errors[item] && (
        <p className='text-danger'> Required </p>
      )}
    </div>
  );
};

export const FileUpload = ({
  title,
  item,
  formik,
  col,
  edit,
  value,
  hideView,
  acceptFile,
}) => {
  const [loading, setLoading] = useState(false);
  const uploadImage = async (image) => {
    try {
      setLoading(true);
      const newForm = new FormData();

      newForm.append(`image`, image, image.name);
      console.log('New Form', newForm);
      const { data } = await axios.post(`${URI}/api/upload/`, newForm, {
        'Content-Type': 'multipart/form-data',
      });
      // console.log(data);
      formik.setFieldValue(item, data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='file-upload'>
      <label> {title} </label>
      <br />
      {!loading ? (
        <>
          {value ? (
            <div>
              {!hideView ? (
                <img
                  src={`${URI}${value}`}
                  style={{
                    width: '150px',
                    height: '150px',
                    objectFit: 'cover',
                    borderRadius: '12px',
                  }}
                />
              ) : (
                <div>
                  File Uploaded
                  <div>
                    <a href={`${URI}${value}`}> View File </a>
                  </div>
                </div>
              )}

              <a
                className='btn btn-sm btn-danger'
                onClick={() => formik.setFieldValue(item, null)}
              >
                {' '}
                <i className='fa fa-trash'></i>{' '}
              </a>
            </div>
          ) : (
            <div className='input-group'>
              <input
                type='file'
                accept={acceptFile ? acceptFile : ''}
                className='custom-file-input'
                onChange={(e) => uploadImage(e.target.files[0])}
              />
            </div>
          )}
        </>
      ) : (
        <div>
          <Skeleton height={10} />
        </div>
      )}
    </div>
  );
};

export const GalleryUpload = ({
  title,
  item,
  formik,
  col,
  edit,
  value,
  hideView,
}) => {
  const [loading, setLoading] = useState(false);
  const uploadImage = async (galleryData) => {
    try {
      setLoading(true);
      const newForm = new FormData();
      if (galleryData.length > 0) {
        Array.from(galleryData).forEach((image, index) => {
          newForm.append(`gallery`, image, image.name);
        });

        // newForm.append(`gallery`, image, image.name);
        const { data } = await axios.post(
          `${URI}/api/upload/gallery`,
          newForm,
          {
            'Content-Type': 'multipart/form-data',
          }
        );
        console.log(' Data of gallery ', data);
        let newDataWithFreshData = value ? value : [];
        formik.setFieldValue(item, [...newDataWithFreshData, ...data]);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 2;

  const getItemStyle = (isDragging, draggableStyle) => ({
    //   userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    width: '120px',
    background: isDragging ? 'lightblue' : '#f1f1f1',
    borderRadius: 5,
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: '250px',
  });

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (value) {
      const arrayToFill = value.map((img, index) => {
        console.log('Image', img);
        return {
          id: `item-${index}`,
          dataURL: img,
        };
      });
      setItems(arrayToFill ? arrayToFill : []);
    }
  }, [value]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    console.log({ reorderedItems });

    const itemInFormik = reorderedItems.map((item) => item.dataURL);
    formik.setFieldValue(item, itemInFormik);
    // setItems(reorderedItems);
  };

  const removeItem = (id) => {
    const newArray = items.filter((val) => val.id !== id);
    const itemInFormik = newArray.map((item) => item.dataURL);
    formik.setFieldValue(item, itemInFormik);
  };

  return (
    <div className='file-upload'>
      <label> {title} </label>
      <br />
      {!loading ? (
        <>
          {value && value.length > 0 ? (
            <>
              <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable'>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div style={{ display: 'inline-flex' }}>
                                <div>
                                  <div
                                    className='card'
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <div>
                                      {!hideView ? (
                                        <img
                                          src={`${URI}${item.dataURL}`}
                                          style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'contain',
                                          }}
                                        />
                                      ) : (
                                        <a
                                          href={`${URI}${item.dataURL}`}
                                          target={'_blank'}
                                        >
                                          {' '}
                                          View{' '}
                                        </a>
                                      )}

                                      <a
                                        className='btn-sm btn-danger'
                                        onClick={() => removeItem(item.id)}
                                      >
                                        <i className='fa fa-trash'> </i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <div style={{ padding: '10px 10px' }}>
                <label style={{ paddingRight: '10px' }}> Add More </label>
                <div className='input-group'>
                  <input
                    type='file'
                    className='custom-file-input'
                    multiple
                    onChange={(e) => uploadImage(e.target.files)}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className='input-group'>
              <input
                type='file'
                className='custom-file-input'
                multiple
                onChange={(e) => uploadImage(e.target.files)}
              />
            </div>
          )}
        </>
      ) : (
        <div>
          <Skeleton height={10} />
        </div>
      )}
    </div>
  );
};
