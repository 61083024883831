import React, { useState } from 'react'
import About from '../../components/about/About'
import Testimonial from '../../components/testimonial/Testimonial'
import OurTeam from '../../components/our-team/OurTeam'
import Breadcrumb from "../../components/breadcrumb/Breadcrumb"
import Header from '../../components/common/Header'
import Footer from '../../components/common/Footer'
import FeaturedCollege from '../../components/featured-college/FeaturedCollege'
import BookAppointmentButton from '../../components/common/BookAppointmentButton'
import { MENU_TABS } from '../../domain/constant'


function Aboutus() {
    const [openModel, setOpenModel] = useState(false)

    return (
        <div>
            <Header activeTab={MENU_TABS.ABOUT} />
            <Breadcrumb path={`About Us`} />
            <About />
            <Testimonial />
            <OurTeam />
            <section className="cta-section p_relative pb_120">
                <div className="auto-container">
                    <div className="inner-container p_relative d_block theme-color-bg b_radius_10 pt_85 pb_75 pl_60 clearfix">
                        <div className="pattern-layer p_absolute l_0 t_0" style={{ backgroundImage: 'url(assets/images/shape/shape-4.png)' }} />
                        <figure className="image-box p_absolute t_0 r_0"><img src="assets/images/visa-application-form-travel-immigration-document-money-passport-map-travel-plan.webp" alt /></figure>
                        <div className="text pull-left">
                            <h2 className="d_block fw_exbold color-white">Are you Looking for Visa <br />Consultation?</h2>
                        </div>
                        <div onClick={() => setOpenModel(true)} className="btn-box pull-right mt_5">
                            <p className="theme-btn">book appointment</p>
                            <BookAppointmentButton openModel={openModel} setOpenModel={setOpenModel} />
                        </div>
                    </div>
                </div>
            </section>
            <FeaturedCollege />
            <Footer />
        </div>

    )
}

export default Aboutus