import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
      <section className="main-footer">
        <div className="image-layer p_absolute r_0 b_0" style={{ backgroundImage: 'url(assets/images/shape/shape-5.png)' }} />
        <div className="footer-top">
          <div className="container">
            <div className="top-inner">
              <div className="info-box">
                <div className="icon-box"><i className="flaticon-message" /></div>
                <span>Email address</span>
                <h5><a href='mailto:needhelp@company.com'>info@nzecgroup.co.in</a></h5>
              </div>
              <figure className="footer-logo"><a href="/"><img src="/assets/images/logo-nzec.png" alt /></a></figure>
              <div className="info-box">
                <div className="icon-box"><i className="flaticon-phone-call" /></div>
                <span>Call now</span>
                <h5><a href="tel:+91 184 400 1188">+91 184 400 1188</a></h5>
                <h5><a href="tel:+91 989 609 2188">+91 989 609 2188</a></h5>
              </div>
            </div>
          </div>
        </div>
        <div className="widget-section">
          <div className="container">
            <div className="row clearfix">
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget about-widget">
                  <div className="widget-title">
                    <h4>About</h4>
                  </div>
                  <div className="widget-content">
                    <p> NZEC Group is a well-known entity established in the year 2015 in the field of sending
                      students abroad for higher education.</p>

                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget links-widget ml_80">
                  <div className="widget-title">
                    <h4>Links</h4>
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix">
                      <li>
                        <Link to={`/about-us`}>About Us</Link>
                      </li>
                      <li>
                        <Link to={`/our-agents`}>Our Agents</Link>
                      </li>
                      <li>
                        <Link to={`/career`}>Career</Link>
                      </li>
                      <li>
                        <Link to={`/partner-with-us`}>Partner with us</Link>
                      </li>
                      <li>
                        <Link to={`/contact-us`}>Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget links-widget">
                  <div className="widget-title">
                    <h4>Explore</h4>
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix">
                      <li>
                        <Link to={`/services`}>Services</Link>
                      </li>
                      <li>
                        <Link to={`/countries`}>Countries</Link>
                      </li>
                      <li>
                        <Link to={`/terms-and-conditions`}>Terms of Use</Link>
                      </li>
                      <li>
                        <Link to={`/privacy-policy`}>Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget newsletter-widget">
                  <div className="widget-title">
                    <h4>Social Media</h4>
                  </div>
                  <div className="widget-content">
                    <ul className="social-links clearfix">
                      <li><a href="/" target="_blank" ><i className="fab fa-twitter" /></a></li>
                      <li><a href="/" target="_blank" ><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="https://www.linkedin.com/company/nzec-group-study-abroad/about/" target="_blank"><i className="fab fa-linkedin" /></a></li>
                      <li><a href="https://www.instagram.com/nzecgroup/?hl=en" target="_blank"><i className="fab fa-instagram" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget newsletter-widget">
                  <div className="widget-title">
                    <h4>Newsletter</h4>
                  </div>
                  <div className="widget-content">
                    <form action="/" method="post">
                      <div className="form-group">
                        <input type="email" name="email" placeholder="Email address" required />
                        <button type="submit"><i className="fas fa-paper-plane" /></button>
                      </div>
                      <div className="form-group">
                        <div className="check-box">
                          <input className="check" type="checkbox" id="checkbox" />
                          <label htmlFor="checkbox">I agree to all your terms and policies</label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="copyright centred">
            <p>© Copyright 2024 by <a href="/">nzec</a>.com</p>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Footer