import React from 'react'
import Header from '../../components/common/Header'
import Footer from '../../components/common/Footer'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { CountryData } from '../../models/country'
import ContactForm from '../../components/contact/ContactForm'
import { Link, useParams } from 'react-router-dom';
import { MENU_TABS } from '../../domain/constant'



function SingleCountry({ }) {
    const params = useParams()
    return (
        <div>
            <Header activeTab={MENU_TABS.COUNTRIES} />
            <Breadcrumb path={`Country`} />
            <div className="service-details">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                            <div className="service-sidebar">
                                <div className="sidebar-widget sidebar-categories">
                                    <ul className="categories-list clearfix">
                                        {
                                            CountryData && CountryData.map((item) => {
                                                return (
                                                    <li>
                                                        <Link to={`/country/${item.slug}`}>
                                                            <div className="active">{item.title}<i className="flaticon-right-arrow" />
                                                            </div>
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="contact-form">
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                            {
                                CountryData && CountryData.map((item) => {
                                    if (item && item.slug == params.slug) {
                                        return (
                                            <div className="service-details-content">
                                                <div className="content-style-one">
                                                    <figure className="image-box"><img src={item.img} alt /></figure>
                                                    <div className="group-title">
                                                        <h2>{item.title}</h2>
                                                    </div>
                                                    <div className="text">
                                                        <h3>{item.short_desc}</h3>
                                                        <p>{item.desc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            null
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default SingleCountry