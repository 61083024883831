export const LIMIT = 10;
export const UPLOAD_CONST = '';
export const URI = process.env.REACT_APP_URI;
export const UPLOAD_URI = 'https://zixdo.com/images/';

export const MENU_TABS = {
    HOME: "HOME",
    ABOUT: "ABOUT",
    SERVICE: "SERVICE",
    COUNTRIES: "COUNTRIES",
    CAREER: "CAREER",
    PARTNER_WITH_US: "PARTNER_WITH_US",
    CONTACT_US: "CONTACT_US"
}