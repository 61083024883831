import React from 'react'
import OurProgramCard from './OurProgramCard'
import { ServiceData } from '../../models/service'

function OurProgram() {
    return (
        <div>
            <section className="service-section p_relative ptb-45 bg-grey">
                <div className="container">
                    <div className="inner-container p_relative">
                        <div className="sec-title p_relative d_block mb_40 centred">
                            <span className="p_relative d_block fs_14 lh_20 fw_sbold theme-color mb_7">What we’re offering</span>
                            <h2 className="p_relative d_block lh_55 fw_exbold">Providing Value for <br />Visa Categories</h2>
                        </div>
                        <div className="row clearfix">
                            {ServiceData && ServiceData.map((item, index) => {
                                if (index < 3) {
                                    return (
                                        <OurProgramCard
                                            visa_icon={`flaticon-family`}
                                            img={item.img}
                                            title={item.title}
                                            short_desc={item.short_desc}
                                            link={item.slug}
                                        />
                                    )
                                }

                            })}

                        </div>
                    </div>
                    {/* <div className="more-text centred">Visa &amp; immigration services built specifically for you. <a href="service.html" className="d_iblock fw_sbold theme-color">Find Your Solution</a></div> */}
                </div>
            </section>
        </div>
    )
}

export default OurProgram