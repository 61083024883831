import React, { useEffect } from 'react';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { Routes, Route } from 'react-router-dom';

import Home from './containers/home/Home';
import Aboutus from './containers/about-us/Aboutus';
import Service from "./containers/service/Services"
import SingleService from "./containers/service/SingleService"
import Country from "./containers/country/Country"
import SingleCountry from './containers/country/SingleCountry';
import Contactus from './containers/contact-us/Contactus';
import Career from './containers/career/Career';
import PartnerWithus from './containers/partner-with-us/PartnerWithus';
import PrivacyPolicy from './containers/policy/PrivacyPolicy';
import TermsAndCondition from './containers/policy/TermsAndCondition';
import OurAgent from './containers/about-us/OurAgent';


function App() {
  // useEffect(() => {
  //   const body = document.querySelector('#root');

  //   body.scrollIntoView(
  //     {
  //       behavior: 'smooth',
  //     },
  //     500
  //   );
  // }, []);
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about-us' element={<Aboutus />} />
      <Route path='/our-agents' element={<OurAgent />} />
      <Route path='/services' element={<Service />} />
      <Route path='/service/:slug' element={<SingleService />} />
      <Route path='/countries' element={<Country />} />
      <Route path='/country/:slug' element={<SingleCountry />} />
      <Route path='/contact-us' element={<Contactus />} />
      <Route path='/career' element={<Career />} />
      <Route path='/partner-with-us' element={<PartnerWithus />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/terms-and-conditions' element={<TermsAndCondition />} />
    </Routes>
  );
}

export default App;
