import React from 'react'
import { Link } from 'react-router-dom'

function FeaturedCollegeCard({ featured_img, link }) {
    return (
        <div>
            <div className="clients-logo-box">
                <Link to={link} >
                    <img src={featured_img} alt />
                </Link>
            </div>
        </div>
    )
}

export default FeaturedCollegeCard