import React from 'react'
import OurServiceCard from './OurServiceCard'

function OurService() {
    return (
        <div>
            <section className="service-section p_relative ptb-60">
                <div className="container">
                    <div className="inner-container p_relative ">
                        <div className="sec-title p_relative d_block mb_40 centred">
                            <span className="p_relative d_block fs_14 lh_20 fw_sbold theme-color mb_7">What we’re offering</span>
                            <h2 className="p_relative d_block lh_55 fw_exbold">Providing Value with<br />Our Services</h2>
                        </div>
                        <div className="row clearfix">
                            <OurServiceCard
                                img={`/assets/images/services/seminar-designers.jpg`}
                                title={`College Selection`}
                                short_desc={` We understand that choosing the right college is a pivotal decision in your academic journey, and we're here to simplify the process. `}

                            />
                            <OurServiceCard
                                img={`/assets/images/services/start-up-designers.jpg`}
                                title={`Profile Enhancement`}
                                short_desc={`Our expert team specializes in optimizing your online profile, ensuring it reflects your true strengths and achievements.`}
                            />
                            <OurServiceCard
                                img={`/assets/images/services/elegant-older-woman-holding-resume-document-sitting-modern-office.jpg`}
                                title={`Recommendation Letter Strategy`}
                                short_desc={` Our Recommendation Letter Strategy Service is your secret weapon for advancing your career or educational pursuits. `}
                            />

                        </div>
                    </div>
                    {/* <div className="more-text centred">Visa &amp; immigration services built specifically for you. <a href="service.html" className="d_iblock fw_sbold theme-color">Find Your Solution</a></div> */}
                </div>
            </section>
        </div>
    )
}

export default OurService