import React from 'react'
import { Link } from 'react-router-dom'

function OurServiceCard({ img, title, short_desc, link }) {
    return (
        <div className="col-lg-4 col-md-6 col-sm-12 service-block">
            <div className="service-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div className="inner-box p_relative d_block b_radius_10">
                    <figure className="image-box p_relative d_block"><img src={img} alt /></figure>
                    <div className="lower-content p_relative d_block pt_25 pr_30 pb_30 pl_40">
                        {/* <div className="icon-box p_absolute r_20 w_100 h_100 lh_110 centred fs_65 bg-white theme-color b_radius_10 tran_5 z_2"><i className="flaticon-family" /></div> */}
                        <h3 className="d_block fs_24 lh_30 mb_25">
                            <Link to={`/service/${link}`} className="d_iblock black-color hov-color">{title}</Link>
                        </h3>
                        <p className="mb_15">{short_desc}</p>
                        <div className="link">
                            <Link to={`/service/${link}`}>
                                <div className="p_relative d_iblock fs_14 fw_sbold black-color hov-color pl_12"><i className="fa fa-angle-right" />Read More</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurServiceCard