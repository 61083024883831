import React from 'react'

function GoogleMapAddress() {
    return (
        <div>
            <section className='google-map-address-section'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="google-map-inside-box">
                                <div className="google-map-map">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3465.986394346878!2d76.9823427742347!3d29.69117413522455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390e71c99d4f9183%3A0x32ec08d1add21a3!2sMahila%20Ashram%20Complex!5e0!3m2!1sen!2sin!4v1697716629390!5m2!1sen!2sin" width="100%" height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default GoogleMapAddress