import React, { useState } from 'react'
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { TextInput } from '../Form/Form';
import ContactForm from './ContactForm';
// import { useCreateContact } from "../../shared/hooks/UseContact"

function Contact() {
    // const [data, addContact] = useCreateContact();

    return (
        <div>
            <section className="contact-section ptb-60 bg-grey">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="contact-form-box">
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-6 mt-5">
                            <div className="contact-content">
                                <div className="sec-title p_relative d_block mb_40 centred">
                                    <span className="p_relative d_block fs_14 lh_20 fw_sbold theme-color mb_7">Contact Us</span>
                                    <h2 className="p_relative d_block lh_55 fw_exbold">ContactUs to Get <br />Your Queries Answered</h2>
                                </div>
                                <div className="contact-info-group">
                                    <div className="contact-info-tab mt-4">
                                        <div className="contact-info-icon mr-3">
                                            <img src="/assets/images/icons/mail_con.png" alt="" />
                                        </div>
                                        <div className="contact-info-text">
                                            <a href="mail:">info@nzecgroup.co.in, admission@nzecgroup.co.in
                                            </a>
                                        </div>
                                    </div>
                                    <div className="contact-info-tab mt-4">
                                        <div className="contact-info-icon mr-3">
                                            <img src="/assets/images/icons/phone-call_con.png" alt="" />
                                        </div>
                                        <div className="contact-info-text">
                                            <a href="tel:+91 184 400 1188">+91 184 400 1188</a>
                                        </div>
                                        <div className="contact-info-text">
                                            <a href="tel:+91 989 609 2188">+91 989 609 2188</a>
                                        </div>
                                    </div>
                                    <div className="contact-info-tab mt-4">
                                        <div className="contact-info-icon mr-3">
                                            <img src="/assets/images/icons/placeholder_con.png" alt="" />
                                        </div>
                                        <div className="contact-info-text">
                                            <a href="tel:">SCO 81, 1st Floor Mahila Ashram, behind Bus Stand, Karnal, Haryana 132001
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact