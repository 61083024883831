import React from 'react'

function StatsCard({ stats_number, stats_title }) {
    return (
        <div className="col-md-3 col-6">
            <div className="stats-card button-38" role="button">
                <div className="stats-card-inside-box">
                    <div className="stats-number">
                        <h3>{stats_number}</h3>
                    </div>
                    <div className="stats-title">
                        <p>{stats_title}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatsCard