import React from 'react'
import TestimonialCard from './TestimonialCard'
import Slider from "react-slick";


function Testimonial() {
    var settings = {
        infinite: true,
        slidesToShow: 3,
        autoplay: true,
        arrow: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <div>
            <section className="testimonial-section p_relative pt_110 pb_100 bg-color-2">
                <div className="pattern-layer p_absolute l_0 t_0" style={{ backgroundImage: 'url(assets/images/shape/shape-3.png)' }} />
                <div className="container">
                    <div className="sec-title p_relative d_block mb_50 centred">
                        <span className="p_relative d_block fs_14 lh_20 fw_sbold theme-color mb_7">how we can help</span>
                        <h2 className="p_relative d_block lh_55 fw_exbold">What They’re Saying</h2>
                    </div>
                    <div className="testimonial-slider-box">
                        <Slider {...settings}>
                            <div className="single-testimonial-card">
                                <TestimonialCard
                                    testimonie_img={`/assets/images/testimonial/portrait.png`}
                                    testimonial_content={`The support I received from this website is beyond my expectations. Thanks for making my dream come true.`}
                                    testimonial_person_name={`Nishant Tripathi`}
                                    testimonial_designation={`Student`}
                                />
                            </div>
                            <div className="single-testimonial-card">
                                <TestimonialCard
                                    testimonie_img={`/assets/images/testimonial/3113-removebg-preview.png`}
                                    testimonial_content={`Cracking the entrance exam of an institute is easy but the real challenge is to get the visa. But surprisingly, NZEC made the visa process smooth and easy. 	Their guidance is commendable, they made sure to do all the documentation easily.`}
                                    testimonial_person_name={`Ravi Teja`}
                                    testimonial_designation={`Student`}
                                />
                            </div>
                            <div className="single-testimonial-card">
                                <TestimonialCard
                                    testimonie_img={`/assets/images/testimonial/155200-removebg-preview.png`}
                                    testimonial_content={`I’m really happy with the services provided by team NZEC. Today I’m pursuing my higher studies at an Ivy League university, thanks to NZEC, half of the credits go to their team. They made my journey easy.`}
                                    testimonial_person_name={`Ankita Acharjya`}
                                    testimonial_designation={`Student`}
                                />
                            </div>
                            <div className="single-testimonial-card">
                                <TestimonialCard
                                    testimonie_img={`/assets/images/testimonial/confident.png`}
                                    testimonial_content={`I almost gave up on my dream of studying abroad but then NZEC happened and here I am, studying at my dream college. Thanks, NZEC!`}
                                    testimonial_person_name={`Shubham Pathak`}
                                    testimonial_designation={`Student`}
                                />
                            </div>
                            <div className="single-testimonial-card">
                                <TestimonialCard
                                    testimonie_img={`/assets/images/testimonial/businessman.png`}
                                    testimonial_content={`Never knew getting a visa would be this easy, thanks to the Team NZEC for helping me with my spouse visa.`}
                                    testimonial_person_name={`Brijbihari Awadhiya`}
                                    testimonial_designation={`Student`}
                                />
                            </div>
                            <div className="single-testimonial-card">
                                <TestimonialCard
                                    testimonie_img={`/assets/images/testimonial/5438-removebg-preview.png`}
                                    testimonial_content={`Dreaming is easy but fulfilling that dream is equally tough, wrong. If you have NZEC by your side, nothing is impossible. I was struggling to make my spouse visa but NZEC made it a cakewalk. Thanks, NZEC. Forever grateful!`}
                                    testimonial_person_name={`Ojasvita`}
                                    testimonial_designation={`Student`}
                                />
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Testimonial