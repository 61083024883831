import React from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Header from '../../components/common/Header'
import Footer from '../../components/common/Footer'

function TermsAndCondition() {
    return (
        <div>
            <Header />
            <Breadcrumb path={`Terms And Condition`} />
            <section className="policy-section ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="policy-content">
                                <div className="section-title">
                                    <h3><strong>Privacy Policy</strong></h3>
                                </div>
                                <div className="section-para">
                                    <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default TermsAndCondition