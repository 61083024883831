import React from 'react';

const Banner = () => {
  return (
    <section className='banner-section centred p_relative'>
      <div className='banner-carousel owl-theme owl-carousel owl-dots-none'>
        <div className='slide-item p_relative pt_190 pb_220'>
          <div
            className='image-layer p_absolute'
            style={{
              backgroundImage: 'url(assets/images/banner/Global_citizen_female_digital_banner_1920x1080.jpg)',
            }}
          />
          <div className='auto-container'>
            <div className='content-box p_relative d_block z_5'>

              {/* <div className='btn-box clearfix'>
                <a href='' className='theme-btn btn-one'>
                  discover more
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className='slide-item p_relative pt_190 pb_220'>
          <div
            className='image-layer p_absolute'
            style={{
              backgroundImage: 'url(assets/images/banner/Learning_anywhere_digital_banner_1920x1080.jpg)',
            }}
          />
          <div className='auto-container'>
            <div className='content-box p_relative d_block z_5'>


              {/* <div className='btn-box clearfix'>
                <a href='/' className='theme-btn btn-one'>
                  discover more
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className='slide-item p_relative pt_190 pb_220'>
          <div
            className='image-layer p_absolute'
            style={{
              backgroundImage: 'url(assets/images/banner/Diversity_marae_digital_banner_1920x1080.jpg)',
            }}
          />
          <div className='auto-container'>
            <div className='content-box p_relative d_block z_5'>


              {/* <div className='btn-box clearfix'>
                <a href='/' className='theme-btn btn-one'>
                  discover more
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className='slide-item p_relative pt_190 pb_220'>
          <div
            className='image-layer p_absolute'
            style={{
              backgroundImage: 'url(assets/images/banner/Sustainability_outside_digital_banner_1920x1080.jpg)',
            }}
          />
          <div className='auto-container'>
            <div className='content-box p_relative d_block z_5'>


              {/* <div className='btn-box clearfix'>
                <a href='/' className='theme-btn btn-one'>
                  discover more
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
