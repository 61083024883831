import React from 'react'

function Breadcrumb({ path, }) {
    return (
        <div>
            <section className="page-title p_relative" style={{ backgroundImage: 'url(/assets/images/breadcrumb/12278.jpg)' }}>
                <div className="auto-container">
                    <div className="content-box p_relative pt_170 pb_170">
                        <h1 className="d_block fs_40 lh_50 color_white fw_exbold color-white">{path}</h1>
                        <ul className="bread-crumb p_absolute r_0 b_0 d_iblock pl_30 pr_30 bg-white clearfix pt_4 pb_4">
                            <li className="p_relative d_iblock fs_12 lh_25 color_white fw_sbold pr_13 mr_5"><a href="index.html" className="color_white hov-color">Home</a></li>
                            <li className="p_relative d_iblock fs_12 lh_25 color_white fw_sbold">{path}</li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Breadcrumb