import React from 'react'

function TestimonialCard({ testimonial_content, testimonial_person_name, testimonial_designation, testimonie_img }) {
    return (
        <div className="testimonial-block-one mr-3" >
            <div className="inner-box p_relative d_block bg-white pt_50 pr_40 pb_50 pl_50 b_radius_10">
                <div className="quote"><span>“</span></div>
                <ul className="rating clearfix p_relative d_block mb_15">
                    <li className="p_relative d_iblock pull-left fs_13 lh_20 mr_3"><i className="fa fa-star" /></li>
                    <li className="p_relative d_iblock pull-left fs_13 lh_20 mr_3"><i className="fa fa-star" /></li>
                    <li className="p_relative d_iblock pull-left fs_13 lh_20 mr_3"><i className="fa fa-star" /></li>
                    <li className="p_relative d_iblock pull-left fs_13 lh_20 mr_3"><i className="fa fa-star" /></li>
                    <li className="p_relative d_iblock pull-left fs_13 lh_20 mr_3"><i className="fa fa-star" /></li>
                </ul>
                <div className="text p_relative d_block mb_20">
                    <p>{testimonial_content}</p>
                </div>
                <div className="author-box p_relative d_block pl_100 pt_13 pb_14">
                    <figure className="author-thumb p_absolute l_0 t_0 b_radius_50"><img src={testimonie_img} alt /></figure>
                    <h5 className="d_block fs_18 lh_30 theme-color">{testimonial_person_name}</h5>
                    <span className="designation d_block fs_14 lh_20 fw_medium">{testimonial_designation}</span>
                </div>
            </div>
        </div>
    )
}

export default TestimonialCard