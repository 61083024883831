export const ServiceData = [
    {
        img: `/assets/images/service/seminar-designers.jpg`,
        title: `College Selection`,
        short_desc: `Choosing the right course and college is easy with us. `,
        desc: `When it comes to choosing what to study and where to study, everyone gets confused. We, the NZEC Group help finalize what course and college you should go for. By filtering all the pros and cons of country and college, we can help you apply in your desired college. Keeping in mind your requirements, we shortlist institutes and destinations and make sure the list we make meets your criteria. `,
        slug: `college-selection`
    },
    {
        img: `/assets/images/service/focused-red-pawn-surrounded-by-white-ones.jpg`,
        title: `Profile Enhancement`,
        short_desc: `We enhance your overseas college application with our online engagement and strategic `,
        desc: `With our 12+ years of experience in this industry, we are now well aware of what the colleges overseas need from the students. We curate the students’ profile in such a way that it is bound to stand out. We guide the students to strengthen their profile and make it more appealing by emphasizing more on activities and social involvement. 
        We suggest the students to be more involved in activities and courses on online platforms.`,
        slug: `Profile-enhancement`
    },
    {
        img: `/assets/images/service/creative-team.jpg`,
        title: `Knowing Your Test`,
        short_desc: `We mold the students to take these exams by making them understand the nature of such tests.`,
        desc: `To make your profile stronger, it is vital for you to take multiple competitive exams, like IELTS, PTE, Duolingo, SAT, etc. We take the responsibility of students not just preparing for these exams but also to instill knowledge in them. Our comprehensive training molds the students in such a way that it gets easier for them to tackle these tests, ensuring they ace these exams and shine. Make your journey pothole-free and smooth with our guidance and personalized strategies. We try our best to navigate the complexities of these tests. `
    },
    {
        img: `/assets/images/service/happy-brunette-woman-shirt-sitting-floor-with-laptop-computer-while-having-idea-looking-camera-gray.jpg`,
        title: `Essay Brainstorming`,
        short_desc: `We ensure that your essays stand out and meet all the requirements of each college.`,
        desc: `Essays are a vital part of your admission process, apart from your academic profile the institute you are applying to will have your essay as a first impression. Each university asks for a unique essay. We help the students to comprehend the essays and guide them on how to write unique and different essays. We try our best to make our students’ essays meet all the requirements of the universities. Students are expected to write multiple essays during the process, we guide formulating essays for each institute.`,
        slug: `essay-brainstorming`
    },
    {
        img: `/assets/images/service/hand-businesswoman-writing-paper-office.jpg`,
        title: `Recommendation Letter Strategy`,
        short_desc: `We curate strategic recommendation letter that acts as a secret weapon for career and academic advancement.`,
        desc: `Our recommendation letter strategy is curated in such a way that your application can surely stand out. Acting as your secret weapon, it pushes your career and educational aspirations forward.  We craft a personalized recommendation letter, that screams about your achievements, strength, and potential, making an ever-lasting mark on the decision-makers. With our strategy, we go in-depth and beyond to make your application unique. `,
        slug: `recommendation-letter-strategy`
    },
    {
        img: `/assets/images/service/hr-managers-are-interviewing-job-applicants-who-fill-out-their-resume-job-application-form-order-consider-accepting-job-as-company.jpg`,
        title: `Resume review and Assessment`,
        short_desc: `A resume plays a vital role in the admission process which is why it is important to make it in a proper format.`,
        desc: `In most of the elite institutes, students are asked to submit their updated resume. We guide the students on what to highlight and what to not, making the application more appealing and inviting. Institutes ask for resumes to have a glance at the students’ experiences and achievements. It is crucial to make your resume in a proper format and to contain all the important information. We help our students build their resumes according to each universities’ needs.`,
        slug: `resume-review-and-assessment`
    },
    {
        img: `/assets/images/service/application-form-information-employment-concept.jpg`,
        title: `Review of online Application forms`,
        short_desc: `It is important for you to make the application error-free, so we are here to make it easy for you.`,
        desc: `All the university has criteria for selection, we try our best to minimize any sort of errors in the students’ application forms. We make the admission process a hassle-free journey.
        Now, ask your worries to take the backseat. Let NZEC do the work.
        `,
        slug: `review-of-online-application-forms`
    },
    {
        img: `/assets/images/service/female-applicant-interviewed-by-hr-mangers.jpg`,
        title: `Admission Interview Preparation`,
        short_desc: `Our team guides the students to appear in the interview, ensuring a positive result.`,
        desc: `Most of the universities take interviews as a part of the admission process. What we do is help the students prepare for the interview, we train them so that their performances stand out among others. We even conduct mock interviews so that the students know what kind of questions they will get asked by the interviewers.`,
        slug: `admission-interview-preparation`
    },
    {
        img: `/assets/images/service/hands-business-team-holding-plant-sprouting-growing-from-golden-coins-banknotes.jpg`,
        title: `Financial Aid Advice / Loan`,
        short_desc: `We, the NZEC Global have a record of helping their students get 100% aid.`,
        desc: `Financial aid can come from scholarships, assistantships, grants, and loans, and we serve our students with various forms of financial assistance. NZEC understands the importance of financial aid which is why we leave no stone unturned to help our students get financial help. We emphasize getting all the financial support so it gets easier for the student to survive and complete the course without any money-related tension in a different country. Our team is committed to supporting the future of our students by making their study abroad journey hassle-free.`,
        slug: `financial-aid-advice-loan`
    },
    {
        img: `/assets/images/service/this-is-your-booking-trip-young-man-planning-vacation-about-sign-purchase-agreement-with-travel-agent.jpg`,
        title: `Visa Interview Preparation`,
        short_desc: `We help the students prepare for a visa interview with the help of our in-visa officers.`,
        desc: `Visa is the most crucial part of the admission process, if the student fails to prove his genuineness and gets rejected by the visa officer even after getting admission to the best university of all then his admission can be at risk. With the assistance of our in-house visa officers, we guide our students to prepare an enticing file. We have a 98.9% success rate in the visa process.`,
        slug: `visa-interview-preparation`
    },
    {
        img: `/assets/images/service/female-speaker-giving-presentation-hall-workshop-audience-conference-hall-participants.jpg`,
        title: `Pre - Departure Session`,
        short_desc: `Moving out is tough but with NZEC Global’s pre-departure session, everything is easy.`,
        desc: `When it comes to moving out to a new country everyone gets nervous, however, we, NZEC Global try to make this journey for our students by organizing a pre-departure session. As we all know building a new world there from scratch is not easy. In this session, we give our students a heads-up on what to expect/what to do /how to commute once they land in the land of a foreign country.`,
        slug: `pre-departure-session`
    },
]