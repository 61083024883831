import React from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Header from '../../components/common/Header'
import Footer from '../../components/common/Footer'
import GoogleMapAddress from '../../components/google-map/GoogleMapAddress'
import ContactForm from '../../components/contact/ContactForm'
import { MENU_TABS } from '../../domain/constant'

function Contactus() {
    return (
        <div>
            <Header activeTab={MENU_TABS.CONTACT_US} />
            <Breadcrumb />
            <section className="contact-section p_relative pt_120 pb_120">
                <div className="auto-container">
                    <div className="row clearfix">
                        {/* <div className="col-lg-8 col-md-12 col-sm-12 content-column">
                            <div className="content-box">
                                <div className="sec-title p_relative d_block mb_40">
                                    <span className="p_relative d_block fs_14 lh_20 fw_sbold theme-color mb_7">how we can help</span>
                                    <h2 className="p_relative d_block lh_55 fw_exbold">Write a Message</h2>
                                </div>
                                <div className="form-inner">
                                    <form method="post" action="sendemail.php" id="contact-form" className="default-form">
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group mb_20">
                                                <input type="text" name="username" placeholder="Your Name" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group mb_20">
                                                <input type="email" name="email" placeholder="Email Address" required />
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group mb_20">
                                                <textarea name="message" placeholder="Leave A Comment" defaultValue={""} />
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn mr-0">
                                                <button className="theme-btn btn-one" type="submit" name="submit-form"><span>Send A Message</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-4">
                            <div className="contact-form-box">
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12 info-column">
                            <div className="info-inner p_relative d_block b_radius_10">
                                <div className="support-box p_relative d_block theme-color-bg pl_30 pt_20 pr_30 pb_20">
                                    <div className="icon-box p_absolute l_30 t_50 fs_35 color-white"></div>
                                    <span className="d_block fs_20 lh_20 fw_medium color-white">Connect with Us</span>
                                </div>
                                <div className="inner p_relative d_block pt_45 pr_30 pb_50 pl_50">
                                    <ul className="info-list clearfix p_relative d_block mb_30">
                                        <li>
                                            <i className='fas fa-phone-square' />
                                            <a href="tel:+91 184 400 1188">+91 184 400 1188</a>
                                        </li>
                                        <li>
                                            <i className='fas fa-phone-square' />
                                            <a href="tel:+91 989 609 2188">+91 989 609 2188</a>
                                        </li>
                                        <li>
                                            <i className='fas fa-envelope' />
                                            <a className='ml-2' href='mailto:info@nzecgroup.co.in'>info@nzecgroup.co.in</a></li>
                                        <hr />
                                        <li>SCO 81, 1st Floor Mahila Ashram, behind Bus Stand, Karnal, Haryana 132001</li>
                                    </ul>

                                </div>
                            </div>
                            <div className="info-inner p_relative d_block b_radius_10 mt-3">
                                <div className="support-box p_relative d_block theme-color-bg pl_30 pt_20 pr_30 pb_20">
                                    <div className="icon-box p_absolute l_30 t_50 fs_35 color-white"></div>
                                    <span className="d_block fs_20 lh_20 fw_medium color-white">For Admission</span>
                                </div>
                                <div className="inner p_relative d_block pt_45 pr_30 pb_50 pl_50">
                                    <ul className="info-list clearfix p_relative d_block mb_30">
                                        <li>
                                            <i className='fas fa-phone-square' />
                                            <a href="tel:+91 989 609 2188">+91 868 305 0188</a>
                                        </li>
                                        <li>
                                            <i className='fas fa-envelope' />
                                            <a className='ml-2' href='mailto:admission@nzecgroup.co.in'>admission@nzecgroup.co.in</a></li>
                                    </ul>

                                </div>
                            </div>

                            <div className="info-inner p_relative d_block b_radius_10 mt-3">
                                <div className="support-box p_relative d_block theme-color-bg pl_30 pt_10 pr_30 pb_10">
                                    <div className="icon-box p_absolute l_50 t_50 fs_35 color-white"><i className="fa fa-buildin" /></div>
                                    <h5 className="d_block fs_18 lh_30 fw_medium color-white">NZEC Group</h5>
                                </div>
                                <div className="inner p_relative d_block pt_45 pr_30 pb_30 pl_50">
                                    <ul className="info-list clearfix p_relative d_block mb_30">
                                        <li>NZEC Group is a well-known entity established in the year 2015 in the field of sending students abroad for higher education.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <GoogleMapAddress />
            <Footer />
        </div>
    )
}

export default Contactus