import React from 'react'
import { Link } from 'react-router-dom'

function CountryCardTwo({ img, title, short_desc }) {
    return (
        <div>
            <div className="service-block">
                <div className="service-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                    <div className="inner-box p_relative d_block b_radius_10 mb_30">
                        <figure className="image-box p_relative d_block">
                            <img src={img} alt />
                        </figure>
                        <div className="lower-content p_relative d_block pt_25 pr_30 pb_30 pl_40">
                            <h3 className="d_block fs_24 lh_30 mb_25">
                                <a href="index.html" className="d_iblock black-color hov-color">{title}</a>
                            </h3>
                            <p className="mb_15">
                                {short_desc}
                            </p>
                            <div className="link">
                                {/* <Link to={`/country/${link}`}>
                                    <div className="p_relative d_iblock fs_14 fw_sbold black-color hov-color pl_12"><i className="fa fa-angle-right" />Read More</div>
                                </Link> */}
                                <Link to={`/`}>
                                    <div className="p_relative d_iblock fs_14 fw_sbold black-color hov-color pl_12"><i className="fa fa-angle-right" />Read More</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CountryCardTwo