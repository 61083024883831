import React from 'react';
import Header from '../../components/common/Header';
import MobileHeader from '../../components/common/MobileHeader';
import Banner from '../../components/banner/Banner';
import About from '../../components/about/About';
import VisaImmigration from '../../components/visa-immigration/VisaImmigration';
import FeaturedCollege from '../../components/featured-college/FeaturedCollege';
import OurProgram from '../../components/our-program/OurProgram';
import Stats from '../../components/stats/Stats';
import Testimonial from '../../components/testimonial/Testimonial';
import OurService from '../../components/our-service/OurService';
import Countries from '../../components/country/Countries';
import GoogleMapAddress from '../../components/google-map/GoogleMapAddress';
import Footer from '../../components/common/Footer';
import Contact from '../../components/contact/Contact';
import AboutIshwar from '../../components/about-ishwar/AboutIshwar';
import OrganisationInfo from '../../components/orgnisation-info/OrganisationInfo';

function Home() {
  return (
    <div className='boxed_wrapper'>
      <Header />
      <MobileHeader />
      <Banner />
      <OrganisationInfo />
      {/* <VisaImmigration /> */}
      <About />
      <FeaturedCollege />
      {/* <AboutIshwar /> */}

      <OurProgram />
      <Stats />
      <Testimonial />
      <OurService />
      {/* <Countries /> */}
      <Contact />
      <GoogleMapAddress />
      <Footer />
    </div>
  );
}

export default Home;
