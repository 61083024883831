export const CountryData = [
    {
        img: `/assets/images/country/new-zealand-flag-waving.jpg`,
        title: `New Zealand`,
        short_desc: `Not only a tourist attraction but New Zealand has become a dream destination for international students for their further studies.`,
        desc: `New Zealand, known for its miraculously stunning landscape and the student-centric and quality education system. Universities like the University of Auckland and Victoria University of Wellington often get a place in the news for their quality education and stay at the top of the list of best universities worldwide. The student-centric approach of New Zealand universities gives the freedom to do critical analysis, have the students their own voice, and promote creativity and practical skills. The diverse range of programs and its commitment to excellence make New Zealand a dream destination for international students.`,
        slug: `new-zealand`
    },
    {
        img: `/assets/images/country/flag-australia-waving-against-clear-blue-sky.jpg`,
        title: `Australia`,
        short_desc: `Australia ceases to fail those who are seeking top-notch higher education there. `,
        desc: `Known for unique wildlife, Australia is an island continent popularly recognized for its diverse culture, stunning landscape, Great Barrier Beef, and Opera House. However, when it comes to quality education, Australia stands second to none. Australian universities often rank high in international assessment by offering diverse academic programs and research opportunities. Its institutes emphasize the upliftment of the students' critical thinking, innovation, and practical skills. Australia’s commitment to quality education makes it best of all, contributing significantly to global research and development. The way Australian universities are showing their dedication towards the upliftment of future leaders worldwide makes it the preferred destination for students seeking top-quality higher education.`,
        slug: `australia`
    },
    {
        img: `/assets/images/country/united-kingdom-uk-flag-waving.jpg`,
        title: `United Kingdom`,
        short_desc: `Like culture, politics, art, and history, the United Kingdom is rich in good universities too.`,
        desc: `United Kingdom is a constitutional monarchy, composing Northern Ireland England, Scotland, and Wales. The United Kingdom is globally recognized for its contribution to art, economics, and politics. The United Kingdom is blessed with some of the best universities, like- the University of Oxford, Cambridge, Imperial College London, etc, that have always been on the list of the world’s best. We are not unaware of the fact that the UK’s education system is beyond good, it focuses on both research and innovation. The country’s rich history and cultural vibrancy add a special effect to its students’ learning experience. Students gain both globally recognized degrees and exposure, this is one of the primary reasons why the United Kingdom is one of the most preferred higher-studies destinations for international students.`,
        slug: `United Kingdom`
    },
    {
        img: `/assets/images/country/canada-flag-blue-sky.jpg`,
        title: `Canada`,
        short_desc: `Canada leaves no chance to provide the best services for its students to get quality education.`,
        desc: `Canada has been ranked among the top in the international assessment of the education system for years now. Canadian universities such as the University of Toronto and McGill University are well known for their contribution to academics and the research world. This country is packed with various well-recognized universities that emphasize practical skills and critical thinking. Canada’s welcoming environment makes it stand out from the rest of the world. For the people seeking quality education, this country has proved itself to be a paradise for them. Due to this country’s diverse culture and inclusive nature, international students find it the most preferred destination for their higher studies. If we talk about accessibility, Canada even provides various kinds of scholarships and support services.`,
        slug: `canada`
    },
    {
        img: `/assets/images/country/eu-flag-euro-flag-flag-european-union-waving.jpg`,
        title: `Europe`,
        short_desc: `Europe shelters everyone keen to pursue higher education and the European education system is the fusion of excellence, diversity, and innovation.`,
        desc: `The European education system is surely commendable, with its commitment to academic upliftment, Europe has been contributing to global education and research which makes the continent the heaven for learning and academic advancement. The cherry on top, Europe provides education in the English language, making it easier for many international students to choose Europe for their higher studies, however, it does provide programs in other languages too. Institutes like Oxford, Sorbonne, and ETH Zurich advocate academic excellence. The European education system consists of a wide range of programs, making it the desired destination for international students. Many countries have even made tuition free or low-cost to make education accessible for all.`,
        slug: `europe`
    },
    {
        img: `/assets/images/country/usa-flag-waving.jpg`,
        title: `USA`,
        short_desc: `USA’s commitment to providing quality education makes them the best of all in the context of higher education.`,
        desc: `The education system of the United States of America is both diverse and renowned and this nature attracts students globally. Getting admission is tough but not impossible, students are required to take tests like the SAT or ACT. The USA is the hub of the best educational institutions for higher studies, like Harvard, MIT, and Stanford, these institutes are known for their research and academic excellence. The US universities focus more on research and adopt creativity and critical thinking. The country’s dedication to providing quality education and producing world leaders has made it gain global recognition. The USA has board-based education, and with academics, USA education boards provide ample extra-curricular opportunities.`,
        slug: `usa`
    },
    {
        img: `/assets/images/country/singapore-flag-waving.jpg`,
        title: `Singapore`,
        short_desc: `Singapore is rich with cultural heritage and renowned universities which ends up attracting international students.`,
        desc: `Singapore is known for its world-class education system, due to which the country has made its place in the list of top countries.  If we talk about the STEM (Science, technology, Engineering, and Mathematics) subjects, Singapore beats everything with its top-class Universities. Singaporean education system focuses on primary, secondary, and tertiary levels, aiming for holistic development. Its education system prepares the students in a dynamic and evolving environment. Plenty of programs are provided by the Singaporean education system which ends up attracting international students. Institutes like the National University of Singapore, and Nanyang Technological University often make their place in the list of top universities.`,
        slug: `singapore`
    },
]