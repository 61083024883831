import React from 'react'

function OrganisationInfo() {
    return (
        <div>
            <section className="organisation-info-sec ptb-45">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="org-info-card">
                                <div className="org-info-inside-box">
                                    <div className="org-info-content">
                                        <div className="org-info-title">
                                            OUR AIM
                                        </div>
                                        <div className="org-info-para">
                                            We aim to provide solutions and guidance to our candidates about issues like study abroad, course, and career guidance. Students can expect all kinds of study abroad-related assistance from our team.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="org-info-card">
                                <div className="org-info-inside-box">
                                    <div className="org-info-content">
                                        <div className="org-info-title">
                                            OUR MISSION
                                        </div>
                                        <div className="org-info-para">
                                            At NZEC Group we believe that studying abroad is a
                                            huge investment and thus it is important that every
                                            student gets accurate and well researched advice
                                            so that he/she is successful in fullling his/her long
                                            term career goals.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="org-info-card">
                                <div className="org-info-inside-box">
                                    <div className="org-info-content">
                                        <div className="org-info-title">
                                            OUR OPERATION
                                        </div>
                                        <div className="org-info-para">
                                            Our goal is to provide solutions and guidance to our students to deal with issues like studying abroad, courses, and careers, making their journey less hectic and tension-free.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default OrganisationInfo