import React from 'react'
// import { Breadcrumb } from 'react-bootstrap'
import OurTeam from '../../components/our-team/OurTeam'
import Header from '../../components/common/Header'
import Footer from '../../components/common/Footer'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Testimonial from '../../components/testimonial/Testimonial'
import FeaturedCollege from '../../components/featured-college/FeaturedCollege'
import { MENU_TABS } from '../../domain/constant'


function OurAgent() {
    return (
        <div>
            <Header activeTab={MENU_TABS.ABOUT} />
            <Breadcrumb path={`Our Team`} />
            <OurTeam />
            <Testimonial />
            <FeaturedCollege />
            <Footer />
        </div>
    )
}

export default OurAgent