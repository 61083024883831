import React from 'react'
import { CountryData } from '../../models/country'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Header from '../../components/common/Header'
import Footer from '../../components/common/Footer'
import CountryCardTwo from '../../components/country/CountryCardTwo'
import { MENU_TABS } from '../../domain/constant'



function Country() {
    return (
        <div>
            <Header activeTab={MENU_TABS.COUNTRIES} />
            <Breadcrumb path={`Countries`} />
            <section className="service-section p_relative pt_120 pb_120">
                <div className="auto-container">
                    <div className='row clearfix'>
                        {
                            CountryData && CountryData.map((item) => {
                                return (
                                    <div className="col-md-4 col-sm-6">
                                        <CountryCardTwo
                                            img={item.img}
                                            title={item.title}
                                            short_desc={item.short_desc}
                                        // link={"/"}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Country;