import React from 'react';

const About = () => {
  return (
    <>
      <section className='about-section p_relative ptb-60 bg-grey'>
        <div
          className='pattern-layer p_absolute l_0'
          style={{ backgroundImage: 'url(assets/images/shape/shape-1.png)' }}
        />
        <div className='container'>
          <div className='row clearfix'>
            <div className='col-lg-7 col-md-12 col-sm-12 content-column'>
              <div className='content_block_1'>
                <div className='content-box p_relative d_block pb_90'>
                  <div className='sec-title p_relative d_block mb_40'>
                    <span className='p_relative d_block fs_14 lh_20 fw_sbold theme-color mb_7'>
                      Get to know us
                    </span>
                    <h2 className='p_relative d_block lh_55 fw_exbold'>
                      About Us
                    </h2>
                  </div>
                  <div className='text p_relative d_block mb_10'>
                    <h5 className='fs_18 fw_medium theme-color mb_35'>
                      We are a renowned New Zealand-based immigration agency with 15+ years of experience in giving students a worry-free study-abroad journey.
                    </h5>
                    {/* <h5 className='fs_18 fw_medium theme-color mb_35'>
                      Your trusted partner on the journey to international opportunities and new horizons.
                    </h5> */}
                    <p>
                      NZEC Group is an eminent New Zealand-based agency, characterized by knowledge and expertise in International education. Supported and guided by our experienced Managing Director, Mr. Ishwar Choudhary, a graduate from some of the renowned institutions of the world with current insights into New Zealand’s educational system.
                    </p>
                  </div>

                  <div className='text p_relative d_block mb_10'>
                    <p>
                      With expertise in advising students on courses, university selection, study methods, student life, financial considerations, and guidance, our agency’s services are not limited to New Zealand only, we extend our expertise to countries like Australia, the United States, the United Kingdom, Canada and more.
                    </p>
                  </div>
                  <div className='text p_relative d_block mb_10'>
                    <p>
                      Our Managing Director is an Education New Zealand-trained agent with over 15 years of experience and holds a Master’s in International Business. Having personally graduated from New Zealand, he passionately helps students in achieving their educational and career aspirations.
                    </p>
                  </div>
                  <div className='text p_relative d_block mb_10'>
                    <p>
                      At NZEC, we understand that studying abroad is a big deal and a significant investment. Therefore, we aim to provide each student with accurate and well-researched information.
                    </p>
                  </div>


                  {
                    <ul className='list-style-one clearfix p_relative d_block'>
                      <li className='p_relative d_iblock fs_16 lh_25 fw_sbold black-color mb_15 pull-left'>
                        95% success rates
                      </li>
                      <li className='p_relative d_iblock fs_16 lh_25 fw_sbold black-color mb_15 pull-left'>
                        Free consultation
                      </li>
                      <li className='p_relative d_iblock fs_16 lh_25 fw_sbold black-color mb_15 pull-left'>
                        Easy &amp; fast process
                      </li>
                      {/* <li className='p_relative d_iblock fs_16 lh_25 fw_sbold black-color mb_15 pull-left'>
                        Expert immigration agents
                      </li> */}
                      <li className='p_relative d_iblock fs_16 lh_25 fw_sbold black-color mb_15 pull-left'>
                        Affordable fees
                      </li>
                      {/* <li className='p_relative d_iblock fs_16 lh_25 fw_sbold black-color mb_15 pull-left'>
                        Over 30 years experience
                      </li> */}
                    </ul>
                  }
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-12 col-sm-12 image-column'>
              <div className='image_block_1'>
                <div className='image-box p_relative d_block pl_70'>
                  <figure className='image p_relative d_block image-1 b_radius_10 tran_5'>
                    <img src='/assets/images/about/ENZ_Trained_Agency_Certificate.png' alt />
                  </figure>
                  {/* <figure
                    className='image image-2 p_absolute r_120 b_radius_10 wow slideInRight animated'
                    data-wow-delay='00ms'
                    data-wow-duration='1500ms'
                  >
                    <img src='assets/images/resource/about-2.jpg' alt />
                  </figure> */}
                  {/* <div className='text p_absolute t_160 b_radius_10 b_shadow_7 bg-white pt_35 pr_40 pb_35 pl_110'>
                    <div className='icon-box p_absolute l_30 t_30 fs_65 theme-color'>
                      <i className='flaticon-customer-service' />
                    </div>
                    <h5 className='d_block fs_18'>Meet Our Expert Agents</h5>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
