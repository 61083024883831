import React from 'react'
import OurProgramCard from '../our-program/OurProgramCard'
import OurTeamCard from './OurTeamCard'
import { TeamData } from '../../models/team'
import Slider from "react-slick";



function OurTeam() {

    var settings = {
        infinite: true,
        slidesToShow: 4,
        autoplay: true,
        arrow: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <div>
            <section className="team-section p_relative sec-pad">
                <div className="container">
                    <div className="upper-box p_relative mb_50">
                        <div className="row clearfix">
                            <div className="col-lg-6 col-md-12 col-sm-12 title-column">
                                <div className="sec-title p_relative d_block mt_6">
                                    <span className="p_relative d_block fs_14 lh_20 fw_sbold mb_7 theme-color">Exerienced Agents</span>
                                    <h2 className="p_relative d_block lh_55 fw_exbold">Meet Our Agents</h2>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 text-column">
                                <div className=" p_relative d_block ">
                                    <p>Lorem ipsum dolor sit amet, consect etur adi pisicing elit sed do eiusmod tempor incididunt ut labore.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="our-team-members-group">
                        <div className="row">
                            {/* {TeamData && TeamData.map((item) => {
                                return (
                                    <>
                                        <div className="col-md-3">
                                            <div className="our-team-card">
                                                <OurTeamCard
                                                    member_img={item.img}
                                                    member_name={item.name}
                                                    member_designation={item.designation}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            })} */}
                            <div className="col-md-3">
                                <div className="team-member-card">
                                    <div className="team-member-inside-box">
                                        <div className="team-member-card-img">
                                            <img src={`/assets/images/2022-01-12.jpg`} alt="" />
                                        </div>
                                        <div className="team-member-card-content">
                                            <div className="team-member-name">
                                                <p>Ishwar Chaudhary</p>
                                            </div>
                                            <div className="team-member-designation">
                                                <p>Managing Director</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="our-team-detailed-content">
                                    <div className="our-team-detailed-content-heading">
                                        <h3>ABOUT MR. ISHWAR CHAUDHARY</h3>
                                    </div>
                                    <hr />
                                    <div className="our-team-detailed-content-info">
                                        <p>Managing Director Mr. Ishwar Singh has over 12 years of experience into International Marketing
                                            since 2010. He start his career from New Zealand as a marketing coordinator from New Zealand
                                            private own college named National Institute of Studies. After succeful two year I changed my job
                                            and joined Linguis International Institute as a Marketing ofcer. In 2014 NZEC Group was opened in
                                            India and started recruiting student globally. The Directors NZEC Group are blessed with the
                                            global experiences in the eld of Education and Immigration consultancy</p>
                                        <p>Mr. Ishwar also has working experience of recruiting overseas student by
                                            working as International Marketing Ofcer for almost seven years in New Zealand
                                            with various Institutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="team-member-card">
                                    <div className="team-member-inside-box">
                                        <div className="team-member-card-img">
                                            <img src={`/assets/images/about/ENZ_Trained_Agency_Certificate.png`} alt="" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default OurTeam