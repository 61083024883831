import React from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Header from '../../components/common/Header'
import Footer from '../../components/common/Footer'
import { MENU_TABS } from '../../domain/constant'

function PartnerWithus() {
    return (
        <div>
            <Header activeTab={MENU_TABS.PARTNER_WITH_US} />
            <Breadcrumb path={`Partner With Us`} />
            <section className="partner-with-us ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="partner-with-us-content">
                                <div className="section-title">
                                    <h3><strong>Partner With Us</strong></h3>
                                </div>
                                <div className="section-para">
                                    <p>Are you planning to study in an Ivy League institute? Big city university or a subtle campus, the choice is yours. You just give us the command and we will make it happen for you, no matter what institute you want to join, your admission is in our hands, safe and confirmed. </p>
                                    <p>
                                        We guide our students to prepare themselves for admission to the world’s best institutes, our services include:
                                    </p>

                                    <div className="display-flex">
                                        <ul>
                                            <li><strong>1.  </strong>College Selection</li>
                                            <li><strong>2.  </strong>Profile Enhancement</li>
                                            <li><strong>3.  </strong>Essay Brainstorming</li>
                                            <li><strong>4.  </strong>Recommendation Letter Strategy</li>
                                            <li><strong>5.  </strong>Resume review and Assessment</li>
                                            <li><strong>6.  </strong>Review of online Application forms</li>

                                        </ul>

                                        <ul>
                                            <li><strong>7.  </strong>Admission Interview preparation</li>
                                            <li><strong>8.  </strong>Scholarship and Assistantship</li>
                                            <li><strong>9.  </strong>Financial Aid Advice / Loan</li>
                                            <li><strong>10.  </strong>Scholarship and Assistantship</li>
                                            <li><strong>11.  </strong>Visa Interview Preparation</li>
                                            <li><strong>12.  </strong>Pre-Departure Session</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="content-footer-text mt-20 text-center">
                                    <p>
                                        We don’t leave our students’ hands until and unless they get admission in their dream colleges.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default PartnerWithus