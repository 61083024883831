import React from 'react'
import StatsCard from './StatsCard'

function Stats() {
    return (
        <div>
            <section className="stats-sec ptb-90">
                <div className="container">
                    <div className="sec-title p_relative d_block mb_50 centred">
                        <span className="p_relative d_block fs_14 lh_20 fw_sbold theme-color mb_7">Why Choose NZEC</span>
                        <h2 className="p_relative d_block lh_55 fw_exbold">Stats in Number</h2>
                    </div>

                    <div className="row">
                        <StatsCard
                            stats_number={`1000+`}
                            stats_title={`Happy Clients`}
                        />
                        <StatsCard
                            stats_number={`97.5%`}
                            stats_title={`Visa Success Rate`}
                        />
                        <StatsCard
                            stats_number={`12+`}
                            stats_title={`Years of experience`}
                        />
                        <StatsCard
                            stats_number={`200+`}
                            stats_title={`Countries`}
                        />

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Stats